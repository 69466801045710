import {encode } from 'cbor-x';
import * as blake2b from 'blakets';


export function hashMessage(
    subject: string,
    name: string,
    value: string,
    sequenceNumber: number
) {
    const hashSubject = cborAndHash(subject)
    const hashName = cborAndHash(name)
    const hashValue = cborAndHash(value)
    const hashSequence = cborAndHash(sequenceNumber.toString())
    return cborAndHash(
        hashSubject + hashName + hashValue + hashSequence
    )
}




const getBlobFromUrl = (myImageUrl:string) => {
    return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        request.open('GET', myImageUrl, true);
        request.responseType = 'blob';
        request.onload = () => {
            resolve(request.response);
        };
        request.onerror = reject;
        request.send();
    })
}

const getDataFromBlob = (myBlob:any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(myBlob);
    })
}

export const convertUrlToImageData = async (myImageUrl:string) : Promise<any> => {
    try {
        const myBlob = await getBlobFromUrl(myImageUrl);
        console.log(myBlob)
        const myImageData = await getDataFromBlob(myBlob);
        console.log(myImageData)
        return myImageData;
    } catch (err) {
        console.log(err);
        return null;
    }
}


function cborAndHash(content: any) { //encodes the given content into a cbor hex string and hashes it
    const cbHex = encode(content)
    return getHash(cbHex)
}

function getHash(content: any, digestLengthBytes = 32) { //hashes a given hex-string content with blake2b_xxx, digestLength is given via the digestLengthBytes parameter, key = null
    // if no digestLength is specified, use the default of 256bits/32bytes -> blake2b_256
    return blake2b.blake2bHex(content, undefined, digestLengthBytes)
}

export const calculatePower = (base: number, exponent: number): number => {
    let result: number = 1;
 
    for (let i = 0; i < exponent; i++) {
        result *= base; // Nhân base với chính nó exponent lần
    }
 
    return result;
}
