import axios from 'axios';

import { Blockfrost, Lucid, MintingPolicy, PolicyId, Unit, utf8ToHex, fromUnit} from "lucid-cardano";
import * as config from "../config/Config";

import { useCallback, useEffect, useState } from "react"
import { sortBy } from "lodash"
import { TokeInfo, ResultInfo } from  "../components/elements/Mint"
import {hashMessage, convertUrlToImageData, calculatePower} from "./utils"

// import * as lib from '@emurgo/cardano-serialization-lib-nodejs';


type Value = ReturnType<typeof fromUnit>

interface MetaData {
  [key: string]: any
}

interface ValueWithName extends Omit<Value, "name"> {
  value: number
  name: string
}


const getUnit = (policyId: PolicyId, name: string): Unit => policyId + utf8ToHex(name)

const getMintingPolicy = (lucid: Lucid, address: string, data: TokeInfo) => {
  const { paymentCredential } = lucid.utils.getAddressDetails(address)

  if(data.allowMint !== 'on') {
    const mintingPolicy: MintingPolicy  = lucid.utils.nativeScriptFromJson({
        type: "all",
        scripts: [
          { 
            type: "sig", 
            keyHash: paymentCredential?.hash! 
          },
          {
            type: "before",
            slot: lucid.utils.unixTimeToSlot(Date.now() + 100000),
          }
          ]
      })
    
    return mintingPolicy
  }else {
    const mintingPolicy: MintingPolicy  = lucid.utils.nativeScriptFromJson({
        type: "all",
        scripts: [
          { 
            type: "sig", 
            keyHash: paymentCredential?.hash! 
          }
          ]
      })
    
    return mintingPolicy
  }
}

const getPolicyId = (lucid: Lucid, mintingPolicy: MintingPolicy) => {
  const policyId: PolicyId = lucid.utils.mintingPolicyToId(mintingPolicy)

  return policyId
}

export const connectWallet = async (wallet : string, network: any) : Promise<any> => {
  // Create Lucid
  let uri = config.BlockFrost_URI_Preview
  let proj = config.BLOCKFROST_PROJ_Preview
  if(network == "Mainnet") {
    uri = config.BlockFrost_URI_Mainnet
    proj = config.BLOCKFROST_PROJ_Mainnet
  }else if(network == "Preprod") {
    uri = config.BlockFrost_URI_Preprod
    proj = config.BLOCKFROST_PROJ_Preprod
  }

  const lucid = await Lucid.new(
        new Blockfrost(
          uri, 
          proj), network,)
  
  // Wallet Connection
  let api: any;
  if (!window.cardano) {
    return "No Wallet";
  }

  if (wallet == "nami") {
    if (!window.cardano.nami) {
      alert("Please install Nami Wallet!");
    }
    try {
      api = await window.cardano.nami.enable();
    } catch (err) {
      return "Decline Access";
    }
  }
  else if (wallet == "eternl") {
    if (!window.cardano.eternl) {
      alert("Please install eternl Wallet!");
    }
    try {
      api = await window.cardano.eternl.enable();
    } catch (err) {
      return "Decline Access";
    }
  }
  lucid.selectWallet(api);

  const addr = await lucid.wallet.address();
  console.log(addr);
  // const address = lib.BaseAddress.from_address(
  //   lib.Address.from_bytes(addr)
  // )?.payment_cred().to_keyhash;, address

  // console.log("bada ad ")

  return lucid;
}


const registerToken = async(
  lucid: Lucid, 
  policyId : any, 
  unit : any, 
  tokenInfo: TokeInfo
 ): Promise<any> => {
    const payloads:MetaData = {
      "subject" : unit,
      "policy" : policyId
    }


    const address = await lucid.wallet.address();

    const entities = ['name', 'description', 'ticker', 'url', 'icon', 'decimals', 'logo']
    for (const key in tokenInfo) {
      if(entities.indexOf(key) > -1 && tokenInfo[key]) {
        if(key == 'logo') {
          const iconUrl = config.getwayIpfs + tokenInfo[key].toString()
          const iconDataFull = await convertUrlToImageData(iconUrl)
          const iconData = iconDataFull.toString().replace('data:image/png;base64,','')
          tokenInfo[key] = iconData
        }

        const sequenceNumber = 0;
        const hash = hashMessage(
            payloads.subject, key, tokenInfo[key], sequenceNumber
        );

        const signed = await lucid.newMessage(address, hash).sign();
        console.log(signed);
        // const signed =  await lucid.wallet.signMessage(address,hash)

         payloads[key] = {
            "sequenceNumber": sequenceNumber,
            "value": tokenInfo[key].toString(),
            "signatures": [
                {
                    "signature": signed.signature.toString(),
                    "publicKey": signed.key.toString()
                }
            ]
          }
        console.log(key, tokenInfo[key])
        console.log('hash', hash)
        console.log(payloads[key])
        console.log("*******************************")
      }
    }
    // const address = await lucid.wallet.address();
    // const subjectHash = await cborAndHash(unit);

    // for (const key in tokenInfo) {
    //   if(tokenInfo[key] && key != "totalSupply" && key != "version" && key != "icon" && key != "register") {
    //     console.log(key, " - ", tokenInfo[key].toString())
    //     const propertyNameHash =  await cborAndHash(key);
    //     const propertyValueHash = await cborAndHash(tokenInfo[key])
    //     const sequenceNumberHash = await cborAndHash(0);
    //     const content2Hash = subjectHash + propertyNameHash + propertyValueHash + sequenceNumberHash;
    //     const finalHash = await getHash(content2Hash);
    //     console.log(subjectHash)
    //     console.log(propertyNameHash)
    //     console.log(propertyValueHash)
    //     console.log(sequenceNumberHash)
    //     console.log('content2Hash', content2Hash)
    //     console.log("finalHash", finalHash)
    //     const signed =  await lucid.wallet.signMessage(address,finalHash)
                

    //     console.log("signed",signed)
    //     payloads[key] = {
    //       "sequenceNumber": 0,
    //       "value": tokenInfo[key].toString(),
    //       "signatures": [
    //           {
    //               "signature": signed.signature.toString(),
    //               "publicKey": signed.key.toString()
    //           }
    //       ]
    //     }
    //   }else if(tokenInfo[key] && key == "icon") {
    //     console.log(key, " - ", tokenInfo[key].toString())
    //     const iconUrl = tokenInfo[key].toString().replace('ipfs://',config.getwayIpfs)
    //     const iconDataFull = await convertUrlToImageData(iconUrl)
    //     const iconData = iconDataFull.toString().replace('data:image/png;base64,','')
    //     const propertyNameHash =  await cborAndHash(key);
    //     const propertyValueHash = await cborAndHash(iconData)
    //     const sequenceNumberHash = await cborAndHash(0);
    //     const content2Hash = subjectHash + propertyNameHash + propertyValueHash + sequenceNumberHash;
    //     const finalHash = await getHash(content2Hash);
    //     const signed =  await lucid.wallet.signMessage(address,finalHash)
    //     console.log(signed)
    //     payloads["logo"] = {
    //       "sequenceNumber": 0,
    //       "value": iconData,
    //       "signatures": [
    //           {
    //               "signature": signed.signature.toString(),
    //               "publicKey": signed.key.toString()
    //           }
    //       ]
    //     }
    //   }
    // }
    // console.log(payloads)
    // console.log(tokenInfo)
    return payloads;
}

//////////////////////////////////////////////////////////////  Main Function.
export const mint = async (
  lucid: Lucid,
  tokenInfo: TokeInfo
): Promise<ResultInfo | undefined> => {
  // Get Selected Wallet Address
try{
  if(!tokenInfo.ticker || !tokenInfo.totalSupply) {
    const result : ResultInfo = {
        status: false,
        tokenRegisted : null,
        message: "Missing tiker or totalSupply"
    }
    return result
  } 
  const address = await lucid.wallet.address();
  const mintingPolicy = getMintingPolicy(lucid, address, tokenInfo)
  console.log("mintingPolicy", mintingPolicy)
  const policyId = getPolicyId(lucid, mintingPolicy)
  const unit = getUnit(policyId, tokenInfo.ticker.toString())

  // await registerToken(lucid, policyId, unit, tokenInfo)
  // return "dasdasd"
  
  const payloads:any = {
    ticker : tokenInfo.ticker,
  }
  let decimals = 0
  if (tokenInfo.decimals) { 
    decimals = Number(tokenInfo.decimals)
    payloads.decimals = decimals
  }


  if (tokenInfo.description) payloads.desc = tokenInfo.description
  if (tokenInfo.icon) payloads.icon = "ipfs://" + tokenInfo.icon
  if (tokenInfo.url) payloads.url = tokenInfo.url
  if (tokenInfo.version) payloads.version = tokenInfo.version
  
  const metadata = {
      [policyId]: {
        [payloads.ticker]: payloads
      }
  }; 
  
  let  totalSupply = BigInt(tokenInfo.totalSupply.toString())
  if(decimals > 0) totalSupply =  totalSupply * BigInt(calculatePower(10,decimals))

  console.log("metadata", metadata)
  console.log("policyId", policyId)
  console.log("assetname", unit)
  console.log("totalSupply", totalSupply)
  const tx = await lucid
    .newTx()
    .mintAssets({ [unit]: totalSupply })
    .attachMetadata(config.label, metadata)
    .validTo(Date.now() + 100000)
    .attachMintingPolicy(mintingPolicy)
    .complete()

    const signedTx = await tx.sign().complete()

    const txHash = await signedTx.submit()

    let result = false
    result = await lucid.awaitTx(txHash);

    
    if (result == false) {
      // If minting was failed, return ERROR.
      const result : ResultInfo = {
        status: false,
        tokenRegisted : null,
        message: "Transaction Failed"
      }
      return result
    }

    if(tokenInfo.register) {
      //register token registry
      const registed = await registerToken(lucid, policyId, unit, tokenInfo)
      const result : ResultInfo = {
          status: true,
          tokenRegisted : registed,
          message: "Mint Token & Register Token Succeed!"
      }
      return result
    }else {
      const result : ResultInfo = {
          status: true,
          tokenRegisted : null,
          message: "Mint Token Succeed!"
      }
      return result
    }
  }catch(ex) {
    const result : ResultInfo = {
        status: false,
        tokenRegisted: null,
        message: (ex as Error).message.toString()
    }
    return result
  }
}



export const register = async (
  lucid: Lucid,
  tokenInfo: TokeInfo
): Promise<ResultInfo | undefined> => {
  // Get Selected Wallet Address
try{
     if(!tokenInfo.policyId) {
        const result : ResultInfo = {
            status: false,
            tokenRegisted : null,
            message: "Missing Policy Id"
        }
        return result
      }

      if(!tokenInfo.ticker) {
        const result : ResultInfo = {
            status: false,
            tokenRegisted : null,
            message: "Missing tiker"
        }
        return result
      } 
      const unit = getUnit(tokenInfo.policyId.toString(), tokenInfo.ticker.toString())

      const registed = await registerToken(lucid, tokenInfo.policyId, unit, tokenInfo)
      const result : ResultInfo = {
          status: true,
          tokenRegisted : registed,
          message: "Register Token Succeed!"
      }
      return result

  }catch(ex) {
    const result : ResultInfo = {
        status: false,
        tokenRegisted: null,
        message: (ex as Error).message.toString()
    }
    return result
  }
}


// export const burn = async (
//   lucid: Lucid,
//   burnInfo : BurnInfo
// ): Promise<ResultInfo> => {
//   if(!burnInfo.ticker || !burnInfo.burnAmount) {
//     const result : ResultInfo = {
//         status: false,
//         tokenRegisted : null,
//         message: "Transaction Failed"
//       }
//     return result
//   }

//   console.log("ticker", burnInfo.ticker.toString())
//   const address = await lucid.wallet.address();
//   const mintingPolicy = getMintingPolicy(lucid, address, getMintingPolicy)
//   const policyId = getPolicyId(lucid, mintingPolicy)
//   const unit = getUnit(policyId, burnInfo.ticker.toString())

//   const burnAmount = BigInt(burnInfo.burnAmount.toString())
//   console.log("BurnInfo", burnInfo)
//   console.log("policyId", policyId)
//   console.log("assetname", unit)
//   console.log("burnAmount", -burnAmount)

//   try {
//   const tx = await lucid
//     .newTx()
//     .mintAssets({ [unit]: -burnAmount  })
//     .validTo(Date.now() + 100000)
//     .attachMintingPolicy(mintingPolicy)
//     .complete()

  
    
//     const signedTx = await tx.sign().complete()
//     const txHash = await signedTx.submit()

//     let txResult = false
//     txResult = await lucid.awaitTx(txHash);

    
//     if (txResult == false) {
//       const result : ResultInfo = {
//         status: false,
//         tokenRegisted : null,
//         message: "Transaction Failed"
//       }
//       return result
//     }
//     const result : ResultInfo = {
//         status: true,
//         tokenRegisted : null,
//         message: "Transaction Succeed"
//       }
//     return result
//   }catch(ex) {
//     const result : ResultInfo = {
//         status: false,
//         tokenRegisted : null,
//         message: "user declined tx"
//       }
//     return result
//   }
// }



export const useAssets = (lucid?: Lucid) => {
  // const [assets, setAssets] = useState<Responses["asset"][]>([])
  const [assets, setAssets] = useState<{}[]>([])
  const [lovelace, setLovelace] = useState(0)

  const fetchAssets = useCallback(async () => {
    if (!lucid?.wallet) return

    const utxos = await lucid.wallet.getUtxos()

    const allUtxos = utxos
      .map((u) => Object.keys(u.assets).map((key) => ({ key, value: u.assets[key] })))
      .reduce((acc, curr) => [...acc, ...curr], [])
      .map((a) => ({
        ...fromUnit(a.key),
        value: Number(a.value),
      }))

      
    const lovelaces = allUtxos
      .filter((u) => u.policyId === "lovelace")
      .reduce((acc, curr) => acc + curr.value, 0)

    const utxoAssets = allUtxos
      .filter((u) => u.policyId !== "lovelace")
      .filter((v: Value): v is ValueWithName => v.name !== null)
      .map((a) => ({
        ...a,
        fullyQualifiedAssetName: `${a.policyId}${a.name}`,
      }))

    const sortedAssets = sortBy(
      utxoAssets,
      (a: any) => (Number(a.quantity) === 1 ? 1 : -1)
    )

    setLovelace(lovelaces)
    setAssets(sortedAssets)
    // console.log(lovelace)
  }, [lucid?.wallet])

  useEffect(() => {
    fetchAssets()
  }, [fetchAssets])

  return {
    lovelace,
    assets,
  }
}


export const getTokenInfo = async (
  policyId : string,
  assetName: string,
  network: string
): Promise<any> => {
  let data;

  let uri = config.BlockFrost_URI_Preview
  let proj = config.BLOCKFROST_PROJ_Preview
  if(network == "Mainnet") {
    uri = config.BlockFrost_URI_Mainnet
    proj = config.BLOCKFROST_PROJ_Mainnet
  }else if(network == "Preprod") {
    uri = config.BlockFrost_URI_Preprod
    proj = config.BLOCKFROST_PROJ_Preprod
  }
  const GETURL = `${uri}/assets/${policyId}`+Buffer.from(assetName).toString('hex');
  try {

    await axios({
        url: GETURL,
        method: 'get',
        headers: {
            'project_id': proj,
            'Content-Type': 'application/json'
        }
     })
     .then(response => {
        console.log(response)
        data = response.data;
        console.log("**********hdd*********",data)
     }) 
     .catch(err => {
        console.log(err);
        data = {}     
     });

  } catch(err: any) {
    data = {}
  }
  return data;
}