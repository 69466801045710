export const BLOCKFROST_PROJ_Preview = "previewdJPX7iQqMulco3QH2Z4eGab1pWtCvR52"
export const BlockFrost_URI_Preview = "https://cardano-preview.blockfrost.io/api/v0"
export const NetWork = "Preview"

export const BLOCKFROST_PROJ_Mainnet = "mainnetXfriHurETF9Om1ILUnceoVf0byiZT8rZ"
export const BlockFrost_URI_Mainnet = "https://cardano-mainnet.blockfrost.io/api/v0"
// export const NetWork = "Mainnet"


export const BLOCKFROST_PROJ_Preprod = "preprodJ1D2pjqUKUt27DyRK92d4TfWaSSIsqDi"
export const BlockFrost_URI_Preprod = "https://cardano-preprod.blockfrost.io/api/v0"

export const label = 20
export const getwayIpfs = "https://ipfs.io/ipfs/"
