import React, {useEffect, useState} from "react";
import {mint, useAssets} from "../../utils/Mint";
import {lovelaceToAda} from "../../utils/swap";
import { Switch } from "@mui/material";

export interface TokeInfo {
      [key: string]: any
}

// export interface BurnInfo {
//   ticker : FormDataEntryValue | null 
//   burnAmount: FormDataEntryValue | null 
// }


export interface ResultInfo {
  status : boolean,
  message: string,
  tokenRegisted: any | null
}

const Mint = ({IsConnected, lucid, setIsMinting, setAlertState, address}: any) => {

  const { lovelace /*, assets*/} = useAssets(lucid)
  const [showMint, setShowMint] = useState(true)
  // const [showBurn, setShowBurn] = useState(false)
  // const [showRegister, setShowRegister] = useState(false)
  // const [policyId, setPolicyId] = useState(false)
  // const [ticker, setTicker] = useState(false)

  // function onOffMint() {
  //   if(!showMint) { 
  //     // setShowBurn(false) 
  //     setShowRegister(false)
  //     if (IsConnected) setShowMint(true)
  //     else alert("Please connect Nami or Eternl Wallet!");
  //   }else setShowMint(false)
  // }

  /*
  function onOffBurn() {
    if(!showBurn) { 
      setShowMint(false)
      setShowRegister(false)
      if(IsConnected) setShowBurn(true)
      else alert("Please connect Nami or Eternl Wallet!");
    }else setShowBurn(false)
  }
  */

  // function onOffRegister() {
  //   if(!showRegister) { 
  //     // setShowBurn(false)
  //     setShowMint(false)
  //     if(IsConnected) setShowRegister(true)
  //     else alert("Please connect Nami or Eternl Wallet!");
  //   }else setShowRegister(false)
  // }

  // function cancel() {
  //   // setShowBurn(false)
  //   setShowMint(false)
  // }

  const exportData = (payload: any, filename: string) => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(payload)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = filename;

    link.click();
  };


  const handleSubmitMint = async(e:any) =>{
     // Prevent the browser from reloading the page
    e.preventDefault();


    // Read the form data
    const form = e.target;
    const formData = new FormData(form);
    
    let checkIpfsUrl = true
    if(formData.get('icon')) {
      const regex = /Qm[1-9A-HJ-NP-Za-km-z]{44}/g;
      const urlIcon = formData.get('icon')!.toString()
      checkIpfsUrl = regex.test(urlIcon)
    }

    if (!formData.get('ticker') || !formData.get("totalSupply") || !checkIpfsUrl) {
      if(checkIpfsUrl) setAlertState({msg: "please enter required fields", status : false,  open: true});
      else  setAlertState({msg: "invalid IPFS Url format", status : false,  open: true});
    }else if(formData.get('decimals') && Number(formData.get("decimals")) > 6) {
      setAlertState({msg: "decimals <=6 ", open: true});
    }else {
      const tokenInfo:TokeInfo  = {
        name : formData.get('tokenName'),
        ticker: formData.get('ticker'),
        icon: formData.get('icon'),
        url: formData.get('homeUrl'),
        totalSupply: formData.get('totalSupply'),
        decimals: formData.get('decimals'),
        version: formData.get('version'),
        description: formData.get('desc'),
        allowMint: formData.get('allowMint')
      }

      try {
        
        setIsMinting(true);
      
        const result: ResultInfo|undefined = await mint(lucid, tokenInfo);
        
        setIsMinting(false);
        if(result){
          setAlertState({msg: result?.message, status : result?.status,  open: true});
          if(result.status && result.tokenRegisted) {
            exportData(result.tokenRegisted, result.tokenRegisted.subject+".json")
          }
        }
        else setAlertState({msg: "Error, please try again", status : false, open: true});
        
      }catch(ex) {
        setIsMinting(false)
        setAlertState({msg: "user declined tx", status : false,  open: true});
      }
    }
  }


  // const handleSubmitRegister = async(e:any) =>{
  //    // Prevent the browser from reloading the page
  //   e.preventDefault();

  //   // Read the form data
  //   const form = e.target;
  //   const formData = new FormData(form);
    
  //   let checkIpfsUrl = true
  //   if(formData.get('icon')) {
  //     const regex = /Qm[1-9A-HJ-NP-Za-km-z]{44}/g;
  //     const urlIcon = formData.get('icon')!.toString()
  //     checkIpfsUrl = regex.test(urlIcon)
  //   }

  //   if (!formData.get('ticker') ||  !checkIpfsUrl) {
  //     if(checkIpfsUrl) setAlertState({msg: "please enter required fields", status : false,  open: true});
  //     else  setAlertState({msg: "invalid IPFS Url format", status : false,  open: true});
  //   }else if(formData.get('decimals') && Number(formData.get("decimals")) > 6) {
  //     setAlertState({msg: "decimals <=6 ", open: true});
  //   }else {
  //     const tokenInfo:TokeInfo  = {
  //       policyId: formData.get('policyId'),
  //       name : formData.get('tokenName'),
  //       ticker: formData.get('ticker'),
  //       logo: formData.get('icon'),
  //       url: formData.get('homeUrl'),
  //       decimals: formData.get('decimals'),
  //       description: formData.get('desc'),
  //     }


  //     try {
  //       // console.log(assets)
  //       setIsMinting(true);

  //       const result: ResultInfo|undefined = await register(lucid, tokenInfo);
        
  //       setIsMinting(false);
  //       if(result){
  //         setAlertState({msg: result?.message, status : result?.status,  open: true});
  //         // if(result.status && result.tokenRegisted) {
  //         //   exportData(result.tokenRegisted, result.tokenRegisted.subject+".json")
  //         // }
  //       }
  //       else setAlertState({msg: "Error, please try again", status : false, open: true});
        
  //     }catch(ex) {
  //       setIsMinting(false)
  //       setAlertState({msg: "user declined tx", status : false,  open: true});
  //     }
  //   }
  // }

  // const getInfo = async(e:any) =>{
  //     e.preventDefault();

  //     if(policyId.toString().length == 56 && ticker) {
  //       const info: any  = await getTokenInfo(policyId.toString(), ticker.toString(), network)
  //       console.log(info)
  //     }
  // }


// const handleChangePolicy = async(e:any) => {
//   e.preventDefault();
//   setPolicyId(e.target.value)  
// }

// const handleChangeTicker = async(e:any) => {
//   e.preventDefault();
//   setTicker(e.target.value)
// }

  // const handleSubmitBurn = async(e:any) => {
  //    // Prevent the browser from reloading the page
  //   e.preventDefault();

  //   // Read the form data
  //   const form = e.target;
  //   const formData = new FormData(form);
        
  //   const burnInfo:BurnInfo  = {
  //     ticker: formData.get('ticker'),
  //     burnAmount: formData.get('burnAmount'),
  //   }

  //   try{
  //     setIsMinting(true);      
  //     const result:ResultInfo | undefined= await burn(lucid, burnInfo);
  //     setIsMinting(false);

  //     if(result)
  //         setAlertState({msg: result?.message, status : result?.status , open: true});
  //     else setAlertState({msg: "Error, please try again", status : false,  open: true});

  //   }catch(ex) {
  //     setIsMinting(false)
  //     setAlertState({msg: "user declined tx", status : false, open: true});
  //   }
  // }  

  useEffect(() => {
    setShowMint(true)
    if(!IsConnected) {
      // setShowBurn(false)
      setShowMint(false)
    }
  }, [IsConnected])

  return (
    <div className="flex flex-col  justify-center">
      <div className="bg-black">
        <div className="space-y-12">
          <div className="max-w-screen-2xl  mx-auto my-5">
            <div className="flex flex-col items-center text-center mx-auto">
              {/* <p className="text-white-400 font-logo uppercase mb-4 tracking-widest font-benji">SOLD OUT</p> */}
              <h1 className="font-logo text-white text-5xl font-bold uppercase font-benji">Dano Token</h1>
              <br/>
              {
                IsConnected?
                  <div className="grid grid-cols-2 gap-4 ">
                    <div>
                      <div className="font-logo text-white-400 text-sm font-benji">Wallet</div>
                      <p className="text-yellow-400">{
                        address? 
                        address.replace(address.substring(10,address.length-4),"***") : ""
                      }
                      </p>
                    </div>
                    <div>
                      <div className="font-logo text-white-400 text-sm font-benji">Balance</div>
                      <strong className="text-red-400"> { lovelaceToAda(lovelace).toLocaleString(undefined, {maximumFractionDigits : 2}) }</strong> ADA
                    </div>
                  </div>
              : <div></div>
              }

              { /*
              <div className="mt-8 flex  sm:flex-row sm:justify-center gap-2 font-body">
                <button onClick = { onOffMint} className="rounded-lg mx-auto tracking-wide inline-block bg-white hover:bg-white-200 font-extrabold focus-visible:ring ring-indigo-300 text-sm-3 md:text-xl text-center text-black	outline-none transition duration-100 px-3 py-3 uppercase">
                  Mint Now
                </button>
                
                <button  onClick = {onOffRegister} className="rounded-lg mx-auto tracking-wide inline-block bg-blue-600  hover:bg-white-200 font-extrabold focus-visible:ring ring-indigo-300 text-sm-3 md:text-xl text-center text-white outline-none transition duration-100 px-3 py-3 uppercase" color="error">
                  Register
                </button>
                
                <button  onClick = {onOffBurn} className="rounded-lg mx-auto tracking-wide inline-block bg-orange-600  hover:bg-white-200 font-extrabold focus-visible:ring ring-indigo-300 text-sm-3 md:text-xl text-center text-black outline-none transition duration-100 px-3 py-3 uppercase" color="error">
                  Burn Now
                </button>
              </div>
              */ }

            </div>
          </div>
        </div>
        {/*mint-block*/}
        {
          showMint?

           <div className="max-w-screen-2xl  mx-auto my-5">
            <form className="items-center  mx-auto" method="post" onSubmit= {handleSubmitMint}>
              <div className="flex-col items-center  mx-auto">
                <div className="border-b border-white-900/10 pb-12">
                  

                  <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-10">

                    <div className="sm:col-span-full">
                      <label htmlFor="ticker" className="block text-sm font-medium leading-6 text-white-900">
                        Ticker <span className="text-red-900">*</span> 
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="ticker"
                          id="ticker"
                          autoComplete="ticker"
                          required
                          placeholder="dano"
                          className="block w-full rounded-md border-0 py-1.5 text-white-900 shadow-sm ring-1 ring-inset ring-white-300 placeholder:text-white-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                     <div className="sm:col-span-full">
                      <label htmlFor="total-supply" className="block text-sm font-medium leading-6 text-white-900">
                        Total Supply <span className="text-red-900">*</span>
                      </label>
                      <div className="mt-2">
                        <input
                          type="number"
                          name="totalSupply"
                          id="total-supply"
                          autoComplete="total-supply"
                          required
                          className="block w-full rounded-md border-0 py-1.5 text-white-900 shadow-sm ring-1 ring-inset ring-white-300 placeholder:text-white-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-full">
                      <label htmlFor="icon" className="block text-sm font-medium leading-6 text-white-900">
                        icon (Ipfs CID) <span className="text-gray-600">(Metadata)</span>
                      </label>
                      <div className="mt-2">
                        <input
                          id="icon"
                          name="icon"
                          type="text"
                          autoComplete="icon"
                          className="block w-full rounded-md border-0 py-1.5 text-white-900 shadow-sm ring-1 ring-inset ring-white-300 placeholder:text-white-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder ="Qm.."
                          pattern = "Qm[1-9A-HJ-NP-Za-km-z]{44}"
                        />
                      </div>
                    </div>


                    <div className="col-span-full">
                      <label htmlFor="home-url" className="block text-sm font-medium leading-6 text-white-900">
                        Home Url<span className="text-gray-600">(Metadata)</span>
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="homeUrl"
                          id="home-url"
                          autoComplete="home-url"
                          className="block w-full rounded-md border-0 py-1.5 text-white-900 shadow-sm ring-1 ring-inset ring-white-300 placeholder:text-white-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="https://"
                          pattern = "^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$"
                        />
                      </div>
                    </div>


                    <div className="sm:col-span-5">
                      <label htmlFor="decimals" className="block text-sm font-medium leading-6 text-white-900">
                        Decimals  <span className="text-gray-600">(Metadata)</span>
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="decimals"
                          id="decimals"
                          autoComplete="decimals"
                          className="block w-full rounded-md border-0 py-1.5 text-white-900 shadow-sm ring-1 ring-inset ring-white-300 placeholder:text-white-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder = "6"
                          max = "6"
                          value="6"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-5">
                      <label htmlFor="version" className="block text-sm font-medium leading-6 text-white-900">
                        Version<span className="text-gray-600">(Metadata)</span>
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="version"
                          id="version"
                          autoComplete="version"
                          placeholder="1.0"
                          className="block w-full rounded-md border-0 py-1.5 text-white-900 shadow-sm ring-1 ring-inset ring-white-300 placeholder:text-white-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value="1.0"
                        />
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label htmlFor="desc" className="block text-sm font-medium leading-6 text-white-900">
                        Description <span className="text-gray-600">(Metadata)</span>
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="desc"
                          id="desc"
                          autoComplete="desc"
                          className="block w-full rounded-md border-0 py-1.5 text-white-900 shadow-sm ring-1 ring-inset ring-white-300 placeholder:text-white-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="description"
                        />
                      </div>
                    </div>

                    <fieldset className="col-span-full">
                      <div className="mt-6 space-y-6">
                        <div className="relative flex gap-x-3">
                          <div className="flex h-6 items-center">
                            <Switch  defaultChecked name ="allowMint" />
                          </div>
                          <div className="text-sm leading-6 text-left">
                            <label htmlFor="register" className="font-medium text-left">
                              Allow the next minting
                            </label>
                          </div>
                        </div>
                      </div>
                    </fieldset>


                  </div>
                </div>

              </div>

              <div className="mt-6 flex items-center justify-end gap-x-6">
                { /* <button onClick={cancel} type="button" className="text-sm font-semibold leading-6 text-white-900">
                  Cancel
                </button>
                */ }
                <button
                  type="submit"
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Mint
                </button>
              </div>
            </form>
          </div>
          : <div></div>

        }


         {/*
        {
          showRegister?

          <div className="max-w-screen-2xl  mx-auto my-5">
            <form className="items-center  mx-auto" method="post" onSubmit= {handleSubmitRegister}>
              <div className="flex-col items-center  mx-auto">
                <div className="border-b border-white-900/10 pb-12">
                  <p className="mt-1 text-sm leading-6 text-white-600">Please enter your token information You must sign your input data, then continue to register at <a className="text-red-900" href="https://input-output-hk.github.io/offchain-metadata-tools/">here</a></p>

                  <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-10">

                    <div className="sm:col-span-full">
                      <label htmlFor="ticker" className="block text-sm font-medium leading-6 text-white-900">
                        Policy Id <span className="text-red-900">*</span> 
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="policyId"
                          id="policyId_register"
                          autoComplete="policyId"
                          required
                          className="block w-full rounded-md border-0 py-1.5 text-white-900 shadow-sm ring-1 ring-inset ring-white-300 placeholder:text-white-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onBlur = {getInfo} 
                          onChange = {handleChangePolicy}
                        />
                      </div>
                    </div>


                    <div className="sm:col-span-full">
                      <label htmlFor="ticker" className="block text-sm font-medium leading-6 text-white-900">
                        Ticker <span className="text-red-900">*</span> 
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="ticker"
                          id="ticker_register"
                          autoComplete="ticker"
                          required
                          placeholder="dano"
                          className="block w-full rounded-md border-0 py-1.5 text-white-900 shadow-sm ring-1 ring-inset ring-white-300 placeholder:text-white-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onBlur = {getInfo}
                          onChange = {handleChangeTicker}
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-full">
                      <label htmlFor="token-name" className="block text-sm font-medium leading-6 text-white-900">
                        Token Name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="tokenName"
                          id="token-name"
                          autoComplete="token-name"
                          placeholder = "Danogo Token"
                          className="block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-full">
                      <label htmlFor="icon" className="block text-sm font-medium leading-6 text-white-900">
                        icon (ipfs CID)
                      </label>
                      <div className="mt-2">
                        <input
                          id="icon"
                          name="icon"
                          type="text"
                          autoComplete="icon"
                          className="block w-full rounded-md border-0 py-1.5 text-white-900 shadow-sm ring-1 ring-inset ring-white-300 placeholder:text-white-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder ="Qm..."
                          pattern = "Qm[1-9A-HJ-NP-Za-km-z]{44}"
                        />
                      </div>
                    </div>


                    <div className="col-span-full">
                      <label htmlFor="home-url" className="block text-sm font-medium leading-6 text-white-900">
                        Home Url
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="homeUrl"
                          id="home-url"
                          autoComplete="home-url"
                          className="block w-full rounded-md border-0 py-1.5 text-white-900 shadow-sm ring-1 ring-inset ring-white-300 placeholder:text-white-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="https://"
                          pattern = "^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$"
                        />
                      </div>
                    </div>


                    <div className="sm:col-span-5">
                      <label htmlFor="decimals" className="block text-sm font-medium leading-6 text-white-900">
                        Decimals
                      </label>
                      <div className="mt-2">
                        <input
                          type="number"
                          name="decimals"
                          id="decimals"
                          autoComplete="decimals"
                          className="block w-full rounded-md border-0 py-1.5 text-white-900 shadow-sm ring-1 ring-inset ring-white-300 placeholder:text-white-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value= "6"
                          max = "6"
                        />
                      </div>
                    </div>


                    <div className="col-span-full">
                      <label htmlFor="desc" className="block text-sm font-medium leading-6 text-white-900">
                        Description
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="desc"
                          id="desc"
                          autoComplete="desc"
                          className="block w-full rounded-md border-0 py-1.5 text-white-900 shadow-sm ring-1 ring-inset ring-white-300 placeholder:text-white-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="description"
                        />
                      </div>
                    </div>

                  </div>
                </div>

              </div>

              <div className="mt-6 flex items-center justify-end gap-x-6">
                <button onClick={cancel} type="button" className="text-sm font-semibold leading-6 text-white-900">
                  Cancel
                </button>
                <button
                  type="submit"
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Register
                </button>
              </div>
            </form>
          </div>
          : <div></div>

        }
        */ }


        {/*
        {
          showBurn?
          <div className="max-w-screen-2xl  mx-auto my-2">
            <form className="items-center  mx-auto" method="post" onSubmit={handleSubmitBurn}>
              <div className="flex-col items-center  mx-auto">
                <div className="border-b border-white-900/10 pb-12">                  
                  <strong className="mt-1 text-sm leading-6 text-red-600 text-2xl font-bold ">Are you sure to burn your token</strong>

                  <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label htmlFor="ticker" className="block text-sm font-medium leading-6 text-white-900">
                        Ticker <span className="text-red-900">*</span>
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="ticker"
                          id="ticker"
                          autoComplete="ticker"
                          required
                          placeholder="dano"
                          className="block w-full rounded-md border-0 py-1.5 text-white-900 shadow-sm ring-1 ring-inset ring-white-300 placeholder:text-white-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label htmlFor="burn-amount" className="block text-sm font-medium leading-6 text-white-900">
                        Burn Amount <span className="text-red-900">*</span>
                      </label>
                      <div className="mt-2">
                        <input
                          type="number"
                          name="burnAmount"
                          id="burn-amount"
                          autoComplete="burn-amount"
                          required
                          className="block w-full rounded-md border-0 py-1.5 text-white-900 shadow-sm ring-1 ring-inset ring-white-300 placeholder:text-white-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div className="mt-6 flex items-center justify-end gap-x-6">
                <button onClick = {cancel} type="button" className="text-sm font-semibold leading-6 text-white-900">
                  Cancel
                </button>
                <button
                  type="submit"
                  className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Burn
                </button>
              </div>
            </form>
          </div>
          : <div></div>
        }
        */}
      </div>
    </div>


  );
}

export default Mint;