const Footer = ({network,setNetwork,  setIsConnected }: any) => {

  const handleChangeNetwork = async(e:any) => {
      console.log("network", e.target.value)
      if(e.target.value != network) setIsConnected(false) 
      setNetwork(e.target.value);
  }

  return (
    <footer className="header z-10">
      <div className="flex flex-wrap items-center justify-between container px-4 py-6 mx-auto">
       <div className="space-y-12">
          <div className="max-w-screen-2xl  mx-auto footer">
              <div className="sm:col-span-3">
                <div className="mt-2">
                  <select
                    id="network"
                    name="network"
                    autoComplete="network-name"
                    className="block col-span-3  border-0 py-1.5 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 network"
                    onChange={handleChangeNetwork} value={network}
                  >
                    <option value="Mainnet">Mainnet</option>
                    <option value="Preview">Preview</option>
                    <option value="Preprod">Preprod</option>
                  </select>
                </div>
              </div>
          </div>
        </div>

      </div>
    </footer>
  );
}

export default Footer;